import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "closeIcon"];

  // eslint-disable-next-line no-unused-vars
  toggleIcon(_event) {
    this.closeIconTarget.style.display = this.checkboxTarget.checked ? "initial" : "none";
  }
}
