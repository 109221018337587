import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    finishDate: String,
    expiredLabel: String,
    timerFormat: { type: String, default: "#{days} dni #{hours}:#{minutes}:#{seconds}" },
  };

  static targets = ["label", "timer"];

  static HOURS = 1000 * 60 * 60 * 24;
  static MINUTES = 1000 * 60 * 60;
  static SECONDS = 1000 * 60;

  connect() {
    const finishDate = new Date(this.finishDateValue);
    const userTimezoneOffset = finishDate.getTimezoneOffset() * this.constructor.SECONDS;
    this.endTime = new Date(finishDate.getTime() + userTimezoneOffset);

    this.update();
    this.timer = setInterval(() => {
      this.update();
    }, 1000);
  }

  disconnect() {
    this.stopTimer();
  }

  stopTimer() {
    if (this.timer) clearInterval(this.timer);
  }

  setFinishLabel() {
    this.timerTarget.innerHTML = "Konkurs zakończony!";
    this.timerTarget.classList.add("c-countdown_bar-finished");
    this.labelTarget.style.display = "none";
  }

  update() {
    const difference = this.timeDifference();

    if (difference < 0) {
      this.stopTimer();
      this.setFinishLabel();
      return;
    }

    const days = Math.floor(difference / this.constructor.HOURS);
    const hours = Math.floor((difference % this.constructor.HOURS) / this.constructor.MINUTES);
    const minutes = Math.floor((difference % (this.constructor.MINUTES)) / (this.constructor.SECONDS));
    const seconds = Math.floor((difference % this.constructor.SECONDS) / 1000);

    this.timerTarget.textContent = this.timerFormatValue
      .replace("#{days}", days)
      .replace("#{hours}", hours)
      .replace("#{minutes}", minutes)
      .replace("#{seconds}", seconds);
  }

  timeDifference() {
    return this.endTime - new Date().getTime();
  }
}
