import { Controller } from "stimulus";

/*
  Controller for hiding and showing popup modals on page load based on cookie presence.
  Cookie is added on modal exit icon with addCookie action.
*/
export default class extends Controller {
  static targets = ["modal"];

  // If target is modal - show it with delay when cookie not set
  // If target is not modal (bar, popup, etc) - show it when cookie is not set
  initialize() {
    const cookieName = this.getCookieName(this.modalTarget);

    if (cookieName === "" || cookieName == null) return;

    const cookie = $.cookie(cookieName);
    if (this.modalTarget.classList.contains("modal")) {
      $(this.modalTarget).modal({ onHide: () => this.addCookie() });
      this.openModal(this.modalTarget, cookie);
    } else {
      this.showElement(this.modalTarget, cookie);
    }
  }

  openModal(target, cookie) {
    if (cookie) return;

    window.setTimeout(() => $(target).modal("show"), 2000);
  }

  showElement(target, cookie) {
    if (cookie) return;

    $(target).show();
  }

  addCookie() {
    const cookieName = this.getCookieName(this.modalTarget);
    const expireTime = this.getExpireTime();
    if (cookieName === "" || cookieName == null) return;

    $.cookie(cookieName, "off", { expires: parseInt(expireTime, 10), path: "/" });
  }

  getCookieName(target) {
    const { cookieName } = target.dataset;

    return cookieName;
  }

  getExpireTime() {
    const expireTime = this.modalTarget.dataset.cookieTime;
    return expireTime === undefined ? 365 : expireTime;
  }
}
